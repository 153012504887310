import { subDays, subHours, subMinutes, subSeconds } from "date-fns";
import { mock } from "../lib/axios";

const now = new Date();

mock.onGet("/api/customers").reply(() => {
  const customers = [];

  return [200, { customers }];
});

mock.onGet("/api/customers/1").reply(() => {
  const customer = {};

  return [200, { customer }];
});

mock.onGet("/api/customers/1/emails").reply(() => {
  const emails = [
    {
      id: "5ece2ce3613486d95ffaea58",
      createdAt: subDays(subHours(subMinutes(now, 34), 5), 3).getTime(),
      description: "Order confirmation",
    },
    {
      id: "5ece2ce8cebf7ad1d100c0cd",
      createdAt: subDays(subHours(subMinutes(now, 49), 11), 4).getTime(),
      description: "Order confirmation",
    },
  ];

  return [200, { emails }];
});

mock.onGet("/api/customers/1/invoices").reply(() => {
  const invoices = [
    {
      id: "5ece2cef3e562cbd61996259",
      currency: "$",
      description: "Freelancer Subscription (12/05/2019 - 11/06/2019)",
      issueDate: now.getTime(),
      paymentMethod: "Credit Card",
      status: "paid",
      value: 5.25,
    },
    {
      id: "5ece2cf461b9484866f2968c",
      currency: "$",
      description: "Freelancer Subscription (12/05/2019 - 11/06/2019)",
      issueDate: now.getTime(),
      paymentMethod: "Credit Card",
      status: "paid",
      value: 5.25,
    },
  ];

  return [200, { invoices }];
});

mock.onGet("/api/customers/1/logs").reply(() => {
  const logs = [
    {
      id: "5ece2cfeb6e2ac847bba11ce",
      createdAt: subDays(subMinutes(subSeconds(now, 56), 2), 2).getTime(),
      description: "Purchase",
      ip: "84.234.243.42",
      method: "POST",
      route: "/api/purchase",
      status: 200,
    },
    {
      id: "5ece2d02510484b2952e1e05",
      createdAt: subDays(subMinutes(subSeconds(now, 56), 2), 2).getTime(),
      description: "Purchase",
      ip: "84.234.243.42",
      method: "POST",
      route: "/api/purchase",
      status: 522,
    },
    {
      id: "5ece2d08e2748e4e9788901a",
      createdAt: subDays(subMinutes(subSeconds(now, 23), 8), 2).getTime(),
      description: "Cart remove",
      ip: "84.234.243.42",
      method: "DELETE",
      route: "/api/products/d65654e/remove",
      status: 200,
    },
    {
      id: "5ece2d0c47214e342c2d7f28",
      createdAt: subDays(subMinutes(subSeconds(now, 54), 20), 2).getTime(),
      description: "Cart add",
      ip: "84.234.243.42",
      method: "GET",
      route: "/api/products/d65654e/add",
      status: 200,
    },
    {
      id: "5ece2d11e4060a97b2b57623",
      createdAt: subDays(subMinutes(subSeconds(now, 16), 34), 2).getTime(),
      description: "Cart add",
      ip: "84.234.243.42",
      method: "GET",
      route: "/api/products/c85727f/add",
      status: 200,
    },
    {
      id: "5ece2d16cf6d53d8e33656af",
      createdAt: subDays(subMinutes(subSeconds(now, 30), 54), 2).getTime(),
      description: "View product",
      ip: "84.234.243.42",
      method: "GET",
      route: "/api/products/c85727f",
      status: 200,
    },
    {
      id: "5ece2d1b2ec5071be9286a96",
      createdAt: subDays(subMinutes(subSeconds(now, 40), 56), 2).getTime(),
      description: "Get products",
      ip: "84.234.243.42",
      method: "GET",
      route: "/api/products",
      status: 200,
    },
    {
      id: "5ece2d22e68d5498917e47bc",
      createdAt: subDays(subMinutes(subSeconds(now, 5), 57), 2).getTime(),
      description: "Login",
      ip: "84.234.243.42",
      method: "POST",
      route: "/api/authentication/login",
      status: 200,
    },
  ];

  return [200, { logs }];
});
