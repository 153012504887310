import { createContext, useEffect, useReducer } from "react";
import PropTypes from "prop-types";
//import axios from '../lib/axios';
import axios from "axios";
import { verify, JWT_SECRET } from "../utils/jwt";
import { config } from "../constants";

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

const setSession = (accessToken) => {
  if (accessToken) {
    localStorage.setItem("accessToken", accessToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    localStorage.removeItem("accessToken");
    delete axios.defaults.headers.common.Authorization;
  }
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user } = action.payload;

    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
    };
  },
  LOGIN: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null,
  }),
  REGISTER: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
  DEMO: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
};

const reducer = (state, action) =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

const AuthContext = createContext({
  ...initialState,
  platform: "JWT",
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve(),
  demo: () => Promise.resolve(),
  set_user: () => Promise.resolve(),
});

export const AuthProvider = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = window.localStorage.getItem("accessToken");

        if (accessToken && verify(accessToken, JWT_SECRET)) {
          await setSession(accessToken);

          const response = await axios.get(`${config.api}/user/profile`);

          let user = response.data;

          try {
            const { data: appSettings } = await axios.get(
              `${config.api}/appsettings/appsetting`
            );
            const featuresEnabled = appSettings.find(
              (entry) => entry.type === 7
            );

            if (featuresEnabled) {
              user.features_enabled = JSON.parse(featuresEnabled.settings);
            }
          } catch (err) {}

          const { data: permissionData } = await axios.post(
            `${config.api}/user/getpermissions`
          );

          user.permissions = permissionData;
          try {
            const { data: photoData } = await axios.post(
              `${config.api}/timesheet/getLastPhoto`,
              {
                username_id: user.username_id,
              }
            );

            user.avatar = photoData[0].photo;
          } catch (err) {
            console.log(err);
          }

          dispatch({
            type: "INITIALIZE",
            payload: {
              isAuthenticated: true,
              user,
            },
          });
        } else {
          dispatch({
            type: "INITIALIZE",
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: "INITIALIZE",
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    };

    initialize();
  }, []);

  const login = async (email, password) => {
    const { data, status } = await axios.post(`${config.api}/auth/login/`, {
      username: email,
      password,
      platform: config.platform,
    });

    let { token: accessToken, user } = data;

    if (!user && status === 200)
      throw { message: "The username or password is incorrect." };

    setSession(accessToken);

    try {
      const { data: appSettings } = await axios.get(
        `${config.api}/appsettings/appsetting`
      );
      const featuresEnabled = appSettings.find((entry) => entry.type === 7);

      if (featuresEnabled) {
        user.features_enabled = JSON.parse(featuresEnabled.settings);
      }
    } catch (err) {}

    const { data: permissionData } = await axios.post(
      `${config.api}/user/getpermissions`
    );

    user.permissions = permissionData;

    try {
      const { data: photoData } = await axios.post(
        `${config.api}/timesheet/getLastPhoto`,
        {
          username_id: user.username_id,
        }
      );

      user.avatar = photoData[0].photo;
    } catch (err) {
      console.log(err);
    }

    dispatch({
      type: "LOGIN",
      payload: {
        user,
      },
    });
  };

  const logout = async () => {
    setSession(null);
    dispatch({ type: "LOGOUT" });
  };

  const register = async (first_name, last_name, email, password) => {
    const response = await axios.post(`${config.api}/website/account`, {
      platform: config.platform,
      info: {
        first_name,
        last_name,
        email,
        password,
      },
    });

    if (response.status === 201) {
      await login(email, password);
    } else
      throw new Error("Problem with sign up. Email may already be in use.");
  };

  const demo = async (first_name, last_name, email, password, mobile) => {
    const response = await axios.post(`${config.api}/website/account`, {
      platform: config.platform,
      info: {
        first_name,
        last_name,
        email,
        password,
        mobile,
      },
    });

    if (response.status === 201) {
      await login(email, password);
    } else
      throw new Error("Problem with sign up. Email may already be in use.");
  };

  const set_user = async (user) => {
    dispatch({ type: "LOGIN", payload: { user } });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        platform: "JWT",
        login,
        logout,
        register,
        demo,
        set_user,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AuthContext;
