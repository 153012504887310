import React, { useState, useEffect } from "react";
import useSettings from "src/hooks/useSettings";
import { useParams } from "react-router-dom-v5-compat";
import { Link as RouterLink } from "react-router-dom-v5-compat";
import { Helmet } from "react-helmet-async";

import {
  Box,
  Breadcrumbs,
  Button,
  Container,
  Grid,
  Link,
  Typography,
} from "@mui/material";

import ChevronRightIcon from "src/icons/ChevronRight";

import Wiki from "@rlmonger/wiki/dist";

const PERMISSION = false;
const PERMISSIONS = {
  wiki_create_category: PERMISSION,
  wiki_edit_category: PERMISSION,
  wiki_delete_category: PERMISSION,

  wiki_create_folder: PERMISSION,
  wiki_edit_folder: true,
  wiki_delete_folder: PERMISSION,

  wiki_upload: true,
  wiki_delete: true,
};

const InformationPointWikiPage = () => {
  const { settings } = useSettings();
  const params = useParams();
  return (
    <>
      <Helmet>
        <title>Insight Tags Wiki</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
          py: 8,
        }}
      >
        <Container maxWidth={settings.compact ? "xl" : false}>
          <Grid container justifyContent="space-between" spacing={3}>
            <Grid item>
              <Typography color="textPrimary" variant="h5">
                Insight Tags Wiki
              </Typography>
              <Breadcrumbs
                aria-label="breadcrumb"
                separator={<ChevronRightIcon fontSize="small" />}
                sx={{ mt: 1 }}
              >
                <Link
                  color="textPrimary"
                  component={RouterLink}
                  to="/dashboard/concrete/dashboard"
                  variant="subtitle2"
                >
                  Dashboard
                </Link>
                <Link
                  color="textPrimary"
                  component={RouterLink}
                  to="/dashboard/insight-tags"
                  variant="subtitle2"
                >
                 Insight Tags
                </Link>
                <Typography color="textSecondary" variant="subtitle2">
                  Insight Tags Wiki
                </Typography>
              </Breadcrumbs>
            </Grid>
          </Grid>
          <Box sx={{ mt: 3 }}>
            <Grid container>
              <Wiki
                token={localStorage.accessToken}
                api={settings.api}
                disableBack
                rootFolder={params.uuid}
                loadByID
                permissions={PERMISSIONS}
              />
            </Grid>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default InformationPointWikiPage;
