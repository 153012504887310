import { createSlice } from "@reduxjs/toolkit";
//import axios from "../lib/axios";
import axios from "axios";
import { config } from "../constants";

const BUSINESS_TRIP = "B";
const PERSONAL_TRIP = "P";

const initialState = {
  devices: [], // Each device has a saved trips property with saved trips in it
  vehiclesInUse: [],
  statusOfDevices: {},
  tags: [],
};

const slice = createSlice({
  name: "iotdevices",
  initialState,
  reducers: {
    getDevices(state, action) {
      state.devices = [...action.payload];
    },

    getVehicleTags(state, action) {
      state.tags = [...action.payload];
    },

    saveTrip(state, action) {
      let { logentry, imei, devices } = { ...action.payload };

      // Find Device

      let newDevices = [...devices];
      let index = newDevices.findIndex((entry) => entry.imei === imei);

      if (index >= 0) {
        let device = { ...newDevices[index] };

        // Add Trip To .trips

        if (device) {
          if (!device.trips) device.trips = []; // Initialze Trips

          if (
            !device.trips.find(
              // Check if trip already exists
              (entry) =>
                entry[2].logstart == logentry[2].logstart &&
                entry[2].logstop == logentry[2].logstop
            )
          ) {
            device.trips = [...device.trips, logentry]; // Add Trip To Trips
          }
        }

        newDevices.splice(index, 1, device);
        state.devices = [...newDevices]; // Save Devices
      }
    },

    setLogbookSummary(state, action) {
      let { summary, imei, devices } = { ...action.payload };

      let newDevices = [...devices];
      let index = newDevices.findIndex((entry) => entry.imei === imei);

      if (index >= 0) {
        let device = { ...newDevices[index] };

        if (device) {
          if (!device.logbookSummary) device.logbookSummary = []; // Initialze Summary

          device.logbookSummary = { ...summary }; // Add Summary to Logbook Summary
        }

        newDevices.splice(index, 1, device);
        state.devices = [...newDevices]; // Save Devices
      }
    },

    saveTrips(state, action) {
      let { logEntries, imei, devices, summary } = { ...action.payload };

      let newDevices = [...devices];
      let index = newDevices.findIndex((entry) => entry.imei === imei);

      if (index >= 0) {
        let device = { ...newDevices[index] };

        if (device) {
          if (!device.trips) device.trips = []; // Initialze Trips

          logEntries.forEach((log) => {
            if (
              !device.trips.find(
                // Check if trip already exists
                (entry) =>
                  entry[2].logstart == log[2].logstart &&
                  entry[2].logstop == log[2].logstop
              )
            ) {
              device.trips = [...device.trips, log]; // Add Trip To Trips
            }
          });

          if (summary) device.logbookSummary = { ...summary };
        }

        newDevices.splice(index, 1, device);

        state.devices = [...newDevices]; // Save Devices
      }
    },

    deleteSavedTrip(state, action) {
      let { logentry, devices } = { ...action.payload };
      let { logstart, logstop, imei } = logentry;

      // Get Devices.

      let newDevices = [...devices];
      let index = newDevices.findIndex((entry) => entry.imei === imei);

      // Find Device
      if (index >= 0) {
        let device = { ...newDevices[index] };

        if (device) {
          let tripIndex = device.trips.findIndex(
            (entry) =>
              entry[2].logstart == logstart && entry[2].logstop == logstop
          );

          let trips = [...device.trips];

          if (tripIndex >= 0) trips.splice(tripIndex, 1);

          device = { ...device, trips };
        }
        newDevices.splice(index, 1, device);
        state.devices = [...newDevices]; // Save Devices
      }
    },
    changeLogPurpose(state, action) {
      let { logentry, devices } = { ...action.payload };
      let { logstart, logstop, imei, purpose } = logentry;

      // Get Devices.

      let newDevices = [...devices];
      let index = newDevices.findIndex((entry) => entry.imei === imei);

      // Find Device
      if (index >= 0) {
        let device = { ...newDevices[index] };

        if (device) {
          let tripIndex = device.trips.findIndex(
            (entry) =>
              entry[2].logstart == logstart && entry[2].logstop == logstop
          );

          let trips = [...device.trips];

          if (tripIndex >= 0) {
            let newTrip = [...trips[tripIndex]];

            newTrip[2] = { ...newTrip[2], description: purpose };

            trips.splice(tripIndex, 1, newTrip);
          }

          device = { ...device, trips };
        }
        newDevices.splice(index, 1, device);
        state.devices = [...newDevices]; // Save Devices
      }
    },

    deleteGeofence(state, action) {
      let { imei, devices } = { ...action.payload };

      // Get Devices.

      let newDevices = [...devices];
      let index = newDevices.findIndex((entry) => entry.imei === imei);

      // Find Device
      if (index >= 0) {
        let device = { ...newDevices[index] };

        if (device) {
          device = { ...device, geofence_gps: null, geofence_meters: null };
        }
        newDevices.splice(index, 1, device);
        state.devices = [...newDevices]; // Save Devices
      }
    },

    changeNickname(state, action) {
      let { imei, nickname, devices } = { ...action.payload };

      // Get Devices.

      let newDevices = [...devices];
      let index = newDevices.findIndex((entry) => entry.imei === imei);

      // Find Device
      if (index >= 0) {
        let device = { ...newDevices[index] };

        if (device) {
          device = { ...device, nickname };
        }
        newDevices.splice(index, 1, device);
        state.devices = [...newDevices]; // Save Devices
      }
    },
    changeIcon(state, action) {
      let { imei, icon, devices } = { ...action.payload };

      // Get Devices.

      let newDevices = [...devices];
      let index = newDevices.findIndex((entry) => entry.imei === imei);

      // Find Device
      if (index >= 0) {
        let device = { ...newDevices[index] };

        if (device) {
          device = { ...device, avatar: icon };
        }
        newDevices.splice(index, 1, device);
        state.devices = [...newDevices]; // Save Devices
      }
    },

    changePhoto(state, action) {
      let { imei, photo, devices } = { ...action.payload };

      // Get Devices.

      let newDevices = [...devices];
      let index = newDevices.findIndex((entry) => entry.imei === imei);

      // Find Device
      if (index >= 0) {
        let device = { ...newDevices[index] };

        if (device) {
          device = { ...device, photo };
        }
        newDevices.splice(index, 1, device);
        state.devices = [...newDevices]; // Save Devices
      }
    },
    changeDriver(state, action) {
      let { imei, permdriver, devices } = { ...action.payload };

      // Get Devices.

      let newDevices = [...devices];
      let index = newDevices.findIndex((entry) => entry.imei === imei);

      // Find Device
      if (index >= 0) {
        let device = { ...newDevices[index] };

        if (device) {
          device = { ...device, permdriver };
        }
        newDevices.splice(index, 1, device);
        state.devices = [...newDevices]; // Save Devices
      }
    },

    changeTags(state, action) {
      let { imei, tags, devices } = { ...action.payload };

      // Get Devices.

      let newDevices = [...devices];
      let index = newDevices.findIndex((entry) => entry.imei === imei);

      // Find Device
      if (index >= 0) {
        let device = { ...newDevices[index] };

        if (device) {
          device = { ...device, tags };
        }
        newDevices.splice(index, 1, device);
        state.devices = [...newDevices]; // Save Devices
      }
    },
    changeVehicleGroups(state, action) {
      let { imei, groups, devices } = { ...action.payload };

      // Get Devices.

      let newDevices = [...devices];
      let index = newDevices.findIndex((entry) => entry.imei === imei);

      // Find Device
      if (index >= 0) {
        let device = { ...newDevices[index] };

        if (device) {
          device = { ...device, groups };
        }
        newDevices.splice(index, 1, device);
        state.devices = [...newDevices]; // Save Devices
      }
    },

    saveGeofence(state, action) {
      let { geofence, devices } = { ...action.payload };

      // Get Devices.

      let newDevices = [...devices];
      let index = newDevices.findIndex((entry) => entry.imei === geofence.imei);

      // Find Device
      if (index >= 0) {
        let device = { ...newDevices[index] };

        if (device) {
          device = {
            ...device,
            geofence_gps: geofence.geofence_gps,
            geofence_meters: geofence.geofence_meters,
          };
        }
        newDevices.splice(index, 1, device);
        state.devices = [...newDevices]; // Save Devices
      }
    },

    setVehicleStatus(state, action) {
      //Find All The Vehicles That Return.
      //Change Their Status
      //Change Their Address

      let vehicles = [...action.payload];

      for (let i = 0; i < vehicles.length; i++) {}

      state.devices = [...action.payload];
    },

    getVehiclesInUse(state, action) {
      state.vehiclesInUse = [...action.payload];
    },
    getStatusOfDevices(state, action) {
      state.statusOfDevices = { ...action.payload };
    },
  },
});

export const { reducer } = slice;

export const getDevices = () => async (dispatch) => {
  try {
    const { data } = await axios.post(`${config.api}/user/iotdevices`, {
      platform: config.platform,
    });

    data.sort((a, b) => {
      if (a.nickname.toLowerCase() < b.nickname.toLowerCase()) return -1;
      else return 1;
    });
    dispatch(slice.actions.getDevices(data));
  } catch (err) {
    console.log(err);
    //dispatch(slice.actions.getDevices([]));
  }
};

export const getVehiclesInUse = () => async (dispatch) => {
  const { data } = await axios.get(`${config.api}/vehicles/vehiclesinuse`);

  dispatch(slice.actions.getVehiclesInUse(data.vehiclesInUse));
};

export const getStatusOfDevices = () => async (dispatch) => {
  const { data } = await axios.get(`${config.api}/vehicles/activedevices`);

  dispatch(slice.actions.getStatusOfDevices(data));
};

export const deleteSavedTrip = (params) => async (dispatch, getState) => {
  // Send in IMEI STOP AND START

  // Delete Trip Here to Server And Locally
  const { devices } = getState().iotdevices;

  const { data } = await axios.post(`${config.api}/user/deleteTripv2`, {
    ...params,
  });

  dispatch(slice.actions.deleteSavedTrip({ logentry: params, devices }));
};

export const changeLogDescription = (params) => async (dispatch, getState) => {
  // Change Trip Here to Server And Locally
  const { devices } = getState().iotdevices;

  const { data } = await axios.post(`${config.api}/logbook/changePurpose`, {
    ...params,
  });

  dispatch(slice.actions.changeLogPurpose({ logentry: params, devices }));
};

export const checkTrip = (params) => async (dispatch, getState) => {
  const devices = getState().devices;

  // Delete Trip Here to Server And Locally

  const { data } = await axios.post(`${config.api}/user/checktripv2`, {
    ...params,
  });

  if (data == BUSINESS_TRIP)
    dispatch(slice.actions.saveTrip({ logentry: params, devices }));
  if (data == PERSONAL_TRIP) dispatch(slice.actions.deleteSavedTrip(params));
};

export const saveTrip = (params) => async (dispatch, getState) => {
  const { devices } = getState().iotdevices;

  // Save Trip Here to Server And Locally
  const { data } = await axios.post(`${config.api}/user/tripsv2`, {
    ...params,
  });

  dispatch(
    slice.actions.saveTrip({ logentry: data, imei: params.imei, devices })
  );
};

export const saveTrips = (params) => async (dispatch, getState) => {
  const devices = getState().devices;
  // Save Trip Here to Server And Locally
  const { data } = await axios.post(`${config.api}/user/tripsv2`);

  dispatch(slice.actions.saveTrips(params));
};

export const saveGeofence = (params) => async (dispatch, getState) => {
  const { devices } = getState().iotdevices;
  // Save Trip Here to Server And Locally

  await axios.post(`${config.api}/user/saveGeoFence`, {
    ...params,
  });

  dispatch(slice.actions.saveGeofence({ geofence: params, devices }));
};

export const changeIcon = (params) => async (dispatch, getState) => {
  const { devices } = getState().iotdevices;
  // Save Trip Here to Server And Locally

  await axios.post(`${config.api}/user/changeicon`, {
    imei: params.imei,
    icon: params.icon,
  });

  dispatch(
    slice.actions.changeIcon({
      imei: params.imei,
      icon: params.icon,
      devices,
    })
  );
};

export const changeNickname = (params) => async (dispatch, getState) => {
  const { devices } = getState().iotdevices;
  // Save Trip Here to Server And Locally

  await axios.post(`${config.api}/user/changenickname`, {
    imei: params.imei,
    nickname: params.nickname,
  });

  dispatch(
    slice.actions.changeNickname({
      imei: params.imei,
      nickname: params.nickname,
      devices,
    })
  );
};

export const changePhoto = (params) => async (dispatch, getState) => {
  const { devices } = getState().iotdevices;

  // Save Trip Here to Server And Locally

  dispatch(
    slice.actions.changePhoto({
      imei: params.imei,
      photo: params.photo,
      devices,
    })
  );
};

export const changeDriver = (params) => async (dispatch, getState) => {
  const { devices } = getState().iotdevices;

  const { imei, permdriver } = params;

  // Save Driver Here to Server And Locally

  try {
    const { data } = await axios.patch(
      `${config.api}/vehicles/vehicle?imei=${imei}`,
      {
        permdriver,
      }
    );
  } catch (err) {
    alert("ERROR FOR NOW");
  }

  dispatch(
    slice.actions.changeDriver({
      imei: params.imei,
      permdriver: params.permdriver,
      devices,
    })
  );
};

export const changeTags = (params) => async (dispatch, getState) => {
  const { devices } = getState().iotdevices;
  // Save Trip Here to Server And Locally

  const { status } = await axios.post(`${config.api}/vehicles/vehicleTags`, {
    imei: params.imei,
    tag_ids: params.tags,
  });

  dispatch(
    slice.actions.changeTags({
      imei: params.imei,
      tags: params.tags,
      devices,
    })
  );
};

export const changeVehicleGroups = (params) => async (dispatch, getState) => {
  const { devices } = getState().iotdevices;
  // Save Trip Here to Server And Locally

  const { status } = await axios.post(`${config.api}/vehicles/groups`, {
    imei: params.imei,
    compgroups_ids: params.groups,
  });

  dispatch(
    slice.actions.changeVehicleGroups({
      imei: params.imei,
      groups: params.groups,
      devices,
    })
  );
};

export const deleteGeofence =
  ({ imei }) =>
  async (dispatch, getState) => {
    const { devices } = getState().iotdevices;
    // Save Trip Here to Server And Locally
    await axios.post(`${config.api}/geofences/deleteGeofence`, {
      imei,
    });

    dispatch(slice.actions.deleteGeofence({ imei, devices }));
  };

export const getLogbookEntries = (imei) => async (dispatch, getState) => {
  let { devices } = getState().iotdevices;

  let { data } = await axios.get(`${config.api}/logbook/logentries`, {
    params: { imei },
  });

  let summary = await summaryOfTrips(data);

  dispatch(
    slice.actions.saveTrips({ imei, logEntries: [...data], devices, summary })
  );
};

const summaryOfTrips = (logEntries) => {
  if (logEntries.length !== 0) {
    let businessKmsTravelled = 0;
    let totalKmsTravelled =
      logEntries[logEntries.length - 1][1].obdodometer -
      logEntries[0][0].obdodometer;
    for (let i = 0; i < logEntries.length; i++) {
      // Percentage of business to total travel
      businessKmsTravelled +=
        logEntries[i][1].obdodometer - logEntries[i][0].obdodometer;
    }
    let businessPercent =
      (Number(businessKmsTravelled) / Number(totalKmsTravelled)) * 100;
    return {
      businessKmsTravelled: businessKmsTravelled,
      totalKmsTravelled: totalKmsTravelled,
      businessPercent: isNaN(businessPercent) ? 0 : businessPercent,
    };
  }
};

export const getVehicleStatus = (vehicles) => async (dispatch) => {
  const { data: vehicleStatus } = await axios.get(
    `${config.api}/vehicles/status`,
    {
      params: {
        region: config.region,
        devices: `${vehicles.map((item, index) => item.imei).join(",")}`,
      },
    }
  );

  dispatch(slice.actions.setVehicleStatus(vehicleStatus));
};

export const getVehicleTags = () => async (dispatch) => {
  let { data: tags } = await axios.get(`${config.api}/accounts/companyTags`, {
    params: { type: 2 },
  });

  dispatch(slice.actions.getVehicleTags(tags));
};

export default slice;
