import { createContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Theme, config } from "../constants";

const METRIC = "metric";
const IMPERIAL = "imperial";

const initialSettings = {
  compact: true,
  compactView: false,
  direction: "ltr",
  responsiveFontSizes: true,
  roundedCorners: true,
  theme: Theme.LIGHT,
  iconColor: "Blue",
  accurateAddress: true,
  groupView: false,
  cluster: true,
  colouredCluster: true,
  measurement: METRIC,
  ...config,
};

export const restoreSettings = () => {
  let settings = null;

  try {
    const storedData = window.localStorage.getItem("settings");

    if (storedData) {
      settings = { ...JSON.parse(storedData), ...config };
    } else {
      settings = {
        ...config,
        compact: true,
        compactView: false,
        direction: "ltr",
        responsiveFontSizes: true,
        roundedCorners: true,
        theme: Theme.LIGHT,
        iconColor: "Blue",
        accurateAddress: true,
        groupView: false,
        cluster: true,
        colouredCluster: true,
        measurement: METRIC,

        //window.matchMedia("(prefers-color-scheme: dark)").matches
        // ? THEMES.DARK
        // : THEMES.LIGHT,
      };
    }
  } catch (err) {
    console.error(err);
    // If stored data is not a strigified JSON this will fail,
    // that's why we catch the error
  }

  return settings;
};

export const storeSettings = (settings) => {
  window.localStorage.setItem("settings", JSON.stringify(settings));
};

const SettingsContext = createContext({
  settings: { ...initialSettings, ...config },
  saveSettings: () => {},
});

export const SettingsProvider = (props) => {
  const { children } = props;
  const [settings, setSettings] = useState(initialSettings);

  useEffect(() => {
    const restoredSettings = restoreSettings();

    if (restoredSettings) {
      setSettings({ ...restoredSettings, ...config });
    }
  }, []);

  const saveSettings = (updatedSettings) => {
    setSettings({ ...updatedSettings, ...config });
    storeSettings({ ...updatedSettings, ...config });
  };

  return (
    <SettingsContext.Provider
      value={{
        settings,
        saveSettings,
      }}
    >
      {children}
    </SettingsContext.Provider>
  );
};

SettingsProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const SettingsConsumer = SettingsContext.Consumer;

export default SettingsContext;
