import { createContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import axios from "axios";

const initialMapStats = { resting: 0, moving: 0 };

const MapStatsContext = createContext({
  mapStats: { ...initialMapStats },
  setStats: () => Promise.resolve(),
});

export const MapStatsProvider = (props) => {
  const { children } = props;
  const [mapStats, setMapStats] = useState(initialMapStats);

  useEffect(() => {}, []);

  const setStats = (stats) => {
    let prevStats = { ...mapStats };
    setMapStats({ ...prevStats, ...stats });
  };

  return (
    <MapStatsContext.Provider
      value={{
        mapStats,
        setStats,
      }}
    >
      {children}
    </MapStatsContext.Provider>
  );
};

MapStatsProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const MapStatsConsumer = MapStatsContext.Consumer;

export default MapStatsContext;
