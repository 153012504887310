import PropTypes from "prop-types";
import { matchPath } from "react-router-dom-v5-compat";
import { List, ListSubheader } from "@mui/material";
import NavItem from "./NavItem";

const renderNavItems = ({
  depth = 0,
  items,
  pathname,
  expanded,
  setExpanded,
  user,
}) => (
  <List disablePadding>
    {items.reduce(
      // eslint-disable-next-line no-use-before-define
      (acc, item) =>
        reduceChildRoutes({
          acc,
          item,
          pathname,
          depth,
          expanded,
          setExpanded,
          user,
        }),
      []
    )}
  </List>
);

const reduceChildRoutes = ({
  acc,
  pathname,
  item,
  depth,
  expanded,
  setExpanded,
  user,
}) => {
  const { features_enabled = {} } = user;
  const key = `${item.title}-${depth}`;
  const exactMatch = item.path
    ? !!matchPath(
        {
          path: item.path,
          end: true,
        },
        pathname
      )
    : false;

  if (item.children) {
    const partialMatch = item.path
      ? !!matchPath(
          {
            path: item.path,
            end: false,
          },
          pathname
        )
      : false;

    if (
      (user?.permissions[item.permission] || !item.permission) &&
      (features_enabled[item.feature] !== false || !item.feature)
    ) {
      acc.push(
        <NavItem
          active={partialMatch}
          depth={depth}
          icon={item.icon}
          info={item.info}
          key={key}
          open={partialMatch}
          path={item.path}
          title={item.title}
          expanded={expanded}
          setExpanded={setExpanded}
        >
          {renderNavItems({
            depth: depth + 1,
            items: item.children,
            pathname,
            expanded,
            setExpanded,
            user,
          })}
        </NavItem>
      );
    }
  } else {
    if (
      (user?.permissions[item.permission] || !item.permission) &&
      (features_enabled[item.feature] !== false || !item.feature)
    ) {
      acc.push(
        <NavItem
          active={exactMatch}
          depth={depth}
          icon={item.icon}
          info={item.info}
          key={key}
          path={item.path}
          title={item.title}
          expanded={expanded}
          setExpanded={setExpanded}
        />
      );
    }
  }

  return acc;
};

const NavSection = (props) => {
  const { items, pathname, title, expanded, setExpanded, user, ...other } =
    props;

  // General
  // Management

  return (
    <List
      subheader={
        <ListSubheader
          disableGutters
          disableSticky
          sx={{
            color: "text.primary",
            fontSize: "0.75rem",
            lineHeight: 2.5,
            fontWeight: 700,
            textTransform: "uppercase",
            height: "1.875rem",
          }}
          setExpanded={setExpanded}
        >
          {props.expanded && title}
        </ListSubheader>
      }
      {...other}
    >
      {renderNavItems({
        items,
        pathname,
        expanded,
        setExpanded,
        user,
      })}
    </List>
  );
};

NavSection.propTypes = {
  items: PropTypes.array,
  pathname: PropTypes.string,
  title: PropTypes.string,
};

export default NavSection;
