export enum Theme {
  LIGHT = "LIGHT",
  DARK = "DARK",
  NATURE = "NATURE",
}

enum Environment {
  PROD = "prod",
  DEV = "dev",
}

// Set the current environment
const CURRENT_ENVIRONMENT: Environment = Environment.PROD;

function getApiBaseUrl(environment: Environment): string {
  return environment === Environment.PROD
    ? "https://www.monger.tech/api"
    : "https://portal.monger.tech/api";
}

// Get the API base URL based on the current environment
const apiBaseURL: string = getApiBaseUrl(CURRENT_ENVIRONMENT);

export interface Config {
  k_key: string;
  map_style: string;
  api: string;
  region?: string;
  colors: {
    lightgreen: string;
    green: string;
    purple: string;
    red: string;
    darkblue: string;
    gray: string;
  };
  platform?: number;
  devices: {
    POLL_DEVICES: number[];
    TRIP_DEVICES: number[];
    LIVE_DASHCAM: number[];
    CONCRETE_DEVICES: number[];
    ENVIORNMENT_DEVICES: number[];
  };
}

export const config: Config = {
  k_key: "AIzaSyBHlZ1fzHHd-IocvPyErgnKsF734rMBrqM",
  map_style: "7948149723c8fc57",
  api: apiBaseURL,
  region: undefined,
  colors: {
    lightgreen: "#6CF492",
    green: "#44ce6f",
    purple: "#c679e3",
    red: "#E03616",
    darkblue: "#233B53",
    gray: "#233B53",
  },
  platform: 2,
  devices: {
    POLL_DEVICES: [21],
    TRIP_DEVICES: [
      1, 2, 3, 4, 5, 6, 7, 8, 20, 22, 23, 24, 25, 26, 27, 28, 29, 30,
    ],
    LIVE_DASHCAM: [27, 24],
    CONCRETE_DEVICES: [77],
    ENVIORNMENT_DEVICES: [57],
  },
};
