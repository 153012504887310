import { createSlice } from "@reduxjs/toolkit";
//import axios from "../lib/axios";
import axios from "axios";
import { config } from "../constants";
import moment from "moment";

const initialState = {
  fleetDriverScoreCurrent: 0,
  fleetDriverScorePrevious: 0,
  incidentsCurrent: 0,
  incidentsPrevious: 0,
};

const slice = createSlice({
  name: "user",
  initialState,
  reducers: {
    getAverageScoreCurrent(state, action) {
      state.fleetDriverScoreCurrent = action.payload;
    },
    getAverageScorePrevious(state, action) {
      state.fleetDriverScorePrevious = action.payload;
    },
    getIncidentsCurrent(state, action) {
      state.incidentsCurrent = action.payload;
    },
    getIncidentsPrevious(state, action) {
      state.incidentsPrevious = action.payload;
    },
  },
});

export const { reducer } = slice;

export const getAverageScore =
  ({ startDate, endDate, devices, period = "current" }) =>
  async (dispatch) => {
    const { data } = await axios.get(`${config.api}/v2/safety/fleet`, {
      params: {
        imeis: devices.map((entry) => entry.imei).join(","),
        start_date: moment(startDate).utc().format("YYYY-MM-DDTHH:mm:ss"),
        end_date: moment(endDate).utc().format("YYYY-MM-DDTHH:mm:ss"),
      },
    });

    const sum = Object.keys(data).reduce(
      (partialSum, key) => partialSum + data[key],
      0
    );

    const score = sum / Object.keys(data).length;

    if (period == "current")
      dispatch(slice.actions.getAverageScoreCurrent(score));

    if (period == "previous")
      dispatch(slice.actions.getAverageScorePrevious(score));
  };

export const getIncidents =
  ({ startDate, endDate, action = "fleet", period = "current" }) =>
  async (dispatch) => {
    const { data } = await axios.get(`${config.api}/driversafety/incidents`, {
      params: {
        action: action,
        startDate: moment(startDate).utc().format("YYYY-MM-DDTHH:mm:ss"),
        endDate: moment(endDate).utc().format("YYYY-MM-DDTHH:mm:ss"),
      },
    });

    let totalIncidents = data.reduce(
      (a, b) => Number(a) + Number(b.totalIncidents),
      0
    );

    if (period == "current")
      dispatch(slice.actions.getIncidentsCurrent(totalIncidents));

    if (period == "previous")
      dispatch(slice.actions.getIncidentsPrevious(totalIncidents));
  };

export default slice;
