import { useEffect, useRef, useState } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom-v5-compat";

import {
  Avatar,
  Box,
  Button,
  ButtonBase,
  Divider,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Stack,
  Popover,
  useMediaQuery, // Import useMediaQuery
  Typography,
  useTheme,
} from "@mui/material";
import { StarRate, Phone } from "@mui/icons-material";

import { useSnackbar } from "notistack";
import useAuth from "../../hooks/useAuth";
import CogIcon from "../../icons/Cog";
import UserIcon from "../../icons/User";
import axios from "axios";
import useSettings from "src/hooks/useSettings";

const AccountPopover = () => {
  const anchorRef = useRef(null);
  const { user, logout } = useAuth();
  const { settings } = useSettings();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleLogout = async () => {
    try {
      handleClose();
      await logout();
      navigate("/");
    } catch (err) {
      console.error(err);
      enqueueSnackbar("Unable to logout", {
        anchorOrigin: {
          horizontal: "right",
          vertical: "top",
        },
        variant: "error",
      });
    }
  };

  const urlBase64ToUint8Array = (base64String) => {
    const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
    const base64 = (base64String + padding)
      .replace(/\-/g, "+")
      .replace(/_/g, "/");

    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);

    for (let i = 0; i < rawData.length; ++i) {
      outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
  };

  const subscribeToPushNotifications = () => {
    try {
      const publicVapidKey =
        "BFfGtfDYejGtOHz1necTfqtvQ_u_r7ppsmp0twAmaAqBCP-7bMmIhfiUXt-EhD1FE7kAReV3r3XuvmPFocm7tAI";
      navigator.serviceWorker.ready.then(function (registration) {
        const pushManager = registration.pushManager;
        const userVisibleOnly = true; // Required for Chrome
        const applicationServerKey = urlBase64ToUint8Array(publicVapidKey); // Convert VAPID key from base64 string to Uint8Array

        pushManager
          .subscribe({
            userVisibleOnly,
            applicationServerKey,
          })
          .then(async function (subscription) {
            let newSubscription = JSON.stringify(subscription);
            newSubscription = JSON.parse(newSubscription);
            const { data } = await axios.post(
              `${settings.api}/v2/notifications/push-notifications/subscribe`,
              { ...newSubscription }
            );

            // Send subscription to backend if needed
          })
          .catch(function (error) {
            console.error("Failed to subscribe to push notifications:", error);
          });
      });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if ("Notification" in window) {
      if (Notification.permission === "granted") {
        // Your code for handling granted notification permission
        subscribeToPushNotifications();
      } else if (Notification.permission === "default") {
        // Request permission if not already granted
        Notification.requestPermission().then(function (permission) {
          if (permission === "granted") {
            subscribeToPushNotifications();
            // Your code for handling granted notification permission
          }
        });
      }
    } else {
      console.log("Notifications are not supported on this browser.");
    }
  }, []);

  return (
    <>
      <Stack
        direction={"row"}
        justifyContent={"center"}
        spacing={1}
        alignItems={"center"}
      >
        {/*
        <Button
          variant="outlined"
          sx={{ color: "white" }}
          color="primary"
          startIcon={<StarRate />}
          onClick={() =>
            window.open("https://g.page/r/CcAQpxicXiaAEB0/review", "_blank")
          }
        >
          Leave a Review
        </Button>
        */}

        <Box
          component={ButtonBase}
          onClick={handleOpen}
          ref={anchorRef}
          sx={{
            alignItems: "center",
            display: "flex",
          }}
        >
          <Avatar
            src={`${user.photo}`}
            sx={{
              height: 32,
              width: 32,
            }}
          />
        </Box>
      </Stack>
      <Popover
        anchorEl={anchorRef.current}
        anchorOrigin={{
          horizontal: "center",
          vertical: "bottom",
        }}
        keepMounted
        onClose={handleClose}
        open={open}
        PaperProps={{
          sx: { width: 240 },
        }}
      >
        <Box sx={{ p: 2 }}>
          <Typography color="textPrimary" variant="subtitle2">
            {user.name}
          </Typography>
          <Typography color="textSecondary" variant="subtitle2"></Typography>
        </Box>
        <Divider />
        <Box sx={{ mt: 2 }}>
          {false && (
            <MenuItem component={RouterLink} to="/dashboard/social/profile">
              <ListItemIcon>
                <UserIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography color="textPrimary" variant="subtitle2">
                    Profile
                  </Typography>
                }
              />
            </MenuItem>
          )}
          <MenuItem component={RouterLink} to="/dashboard/account">
            <ListItemIcon>
              <CogIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography color="textPrimary" variant="subtitle2">
                  Settings
                </Typography>
              }
            />
          </MenuItem>
        </Box>
        <Box sx={{ p: 2 }}>
          <Button
            color="primary"
            fullWidth
            onClick={handleLogout}
            variant="outlined"
          >
            Logout
          </Button>
        </Box>
      </Popover>
    </>
  );
};

export default AccountPopover;
