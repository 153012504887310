let colors = {
  mainPurp: "#C981E4",
  mainBlue: "#253C53",
  lightPurp: "#EDC3FC",
  darkPurp: "#8E4AAD",
  mainGreen: "#5CBA6D",
  secondGreen: "#6CF492",
  lightGreen: "#95F9B1",
  red: "#F91E1E",
  lightRed: "#FC4141",
  Blue: "#4B87E8",
  lightBlue: "#4B87E8",
  hireOrange: "#EC9736",
  darkBlue: "#0F054B",
  lightNavy: "#6F90AD",
  background: "#F1F1F1",
  blackText: "#222222",
  Heading: "#233B53",
  lightGrey: "#e2e2e2",
  grey: "#b8b8b8",
  subBlack: "#575757",
  white: "#ffffff",
};

export default colors;
