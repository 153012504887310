import { useEffect } from "react";
import { Link as RouterLink, useLocation } from "react-router-dom-v5-compat";
import PropTypes from "prop-types";
import { Box, Drawer, Hidden } from "@mui/material";
import Logo from "../Logo";
import NavSection from "../NavSection";

const sections = [
  {
    title: "Overview",
    items: [
      {
        title: "Welcome",
        path: "/docs/overview/welcome",
      },
      {
        title: "Getting Started",
        path: "/docs/overview/getting-started",
      },
      {
        title: "Installation Of Trackers/Sensors",
        path: "/docs/overview/installation",
      },
      {
        title: "Setup Trackers/Sensors",
        path: "/docs/overview/setup-tracker",
      },
      {
        title: "Device Information",
        path: "/docs/overview/device-information",
      },
    ],
  },
  {
    title: "Installation",
    items: [
      {
        title: "Sonar",
        path: "/docs/installation/sonar",
      },
      {
        title: "Watchman",
        path: "/docs/installation/watchman",
      },
      {
        title: "Defender",
        path: "/docs/installation/defender",
      },
      {
        title: "Sentinel",
        path: "/docs/installation/sentinel",
      },
    ],
  },

  {
    title: "Assets",
    items: [
      {
        title: "Adding Photos",
        path: "/docs/assets/adding-photo",
      },

      {
        title: "Asset Information",
        path: "/docs/assets/asset-information",
      },
      {
        title: "Geofence",
        path: "/docs/assets/geofence",
      },
    ],
  },

  {
    title: "Drivers",
    items: [
      {
        title: "Adding Drivers",
        path: "/docs/driver/adding-drivers",
      },
      {
        title: "Driver Reports",
        path: "/docs/driver/driver-reports",
      },
      {
        title: "Completing A Shift",
        path: "/docs/driver/scan-in-drivers",
      },
    ],
  },

  {
    title: "People",
    items: [
      {
        title: "People Explained",
        path: "/docs/admin/people/people-explained",
      },
      {
        title: "Adding People",
        path: "/docs/admin/people/adding-people",
      },
    ],
  },
  {
    title: "Groups",
    items: [
      {
        title: "Groups Explained",
        path: "/docs/admin/groups/groups-explained",
      },
      {
        title: "Adding Groups",
        path: "/docs/admin/groups/adding-groups",
      },
    ],
  },
  {
    title: "Jobsites",
    items: [
      {
        title: "Jobsites Explained",
        path: "/docs/admin/jobsites/jobsites-explained",
      },
      {
        title: "Adding Jobsites",
        path: "/docs/admin/jobsites/adding-jobsites",
      },
    ],
  },

  {
    title: "Web App",
    items: [
      {
        title: "Overview Explained",
        path: "/docs/webapp/overview",
      },
      {
        title: "Settings",
        path: "/docs/webapp/settings",
      },
    ],
  },
  {
    title: "Mobile App",
    items: [
      {
        title: "Overview Explained",
        path: "/docs/webapp/overview",
      },
    ],
  },

  {
    title: "Driver Safety",
    items: [
      {
        title: "Driver Safety Explained",
        path: "/docs/driversafety/driver-safety-explained",
      },
    ],
  },
  {
    title: "Asset Management",
    items: [
      {
        title: "Adding Tasks",
        path: "/docs/assetmanagement/adding-tasks",
      },
      {
        title: "Completing Tasks",
        path: "/docs/assetmanagement/completing-tasks",
      },
    ],
  },
  {
    title: "Reporting",
    items: [
      {
        title: "Generating Reports",
        path: "/docs/reports/generate-report",
      },
    ],
  },

  {
    title: "Cold Chain",
    items: [
      {
        title: "Cold-Chain",
        path: "/docs/coldchain/cold-chain",
      },
      {
        title: "Temperature Sensors",
        path: "/docs/coldchain/temperature-sensors",
      },

      {
        title: "Cold Storage",
        path: "/docs/coldchain/cold-storage",
      },
      {
        title: "Cold Delivery",
        path: "/docs/coldchain/cold-delivery",
      },
    ],
  },

  /*
  {
    title: "Timesheet",
    items: [
      {
        title: "Review",
        path: "/docs/overview/installation",
      },
    ],
  },
  */

  {
    title: "Our Devices",
    items: [
      {
        title: "Sonar",
        path: "/docs/devices/sonar",
      },
      {
        title: "Watchman",
        path: "/docs/devices/watchman",
      },
      {
        title: "Defender",
        path: "/docs/devices/defender",
      },
      {
        title: "Sentinel",
        path: "/docs/devices/sentinel",
      },
      {
        title: "Temperature Sensor",
        path: "/docs/devices/temperature-sensors",
      },
      {
        title: "Bluetooth Relay",
        path: "/docs/devices/bluetooth-relay",
      },
      {
        title: "Wired Relay",
        path: "/docs/devices/relay",
      },
    ],
  },

  {
    title: "Timeline",
    items: [
      {
        title: "New Features",
        path: "/docs/timeline/new-features",
      },
      {
        title: "Upcoming Features",
        path: "/docs/timeline/upcoming-features",
      },
    ],
  },
  {
    title: "Support",
    items: [
      {
        title: "Changelog",
        path: "/docs/timeline/new-features",
      },
      {
        title: "Contact",
        path: "/docs/support/contact",
      },
    ],
  },
];

const DocsSidebar = (props) => {
  const { onMobileClose, openMobile } = props;
  const location = useLocation();

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <Hidden lgUp>
        <Box p={2}>
          <RouterLink to="/">
            <Logo />
          </RouterLink>
        </Box>
      </Hidden>
      <Box p={2}>
        {sections.map((section) => (
          <NavSection
            key={section.title}
            pathname={location.pathname}
            sx={{
              "& + &": {
                mt: 3,
              },
            }}
            {...section}
          />
        ))}
      </Box>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
          PaperProps={{
            sx: {
              backgroundColor: "background.default",
              width: 256,
            },
          }}
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden lgDown>
        <Drawer
          anchor="left"
          open
          variant="persistent"
          PaperProps={{
            sx: {
              backgroundColor: "background.paper",
              height: "calc(100% - 64px) !important",
              top: "64px !important",
              width: 256,
            },
          }}
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

DocsSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default DocsSidebar;
