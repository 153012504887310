import { useState } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom-v5-compat";
import PropTypes from "prop-types";
import useAuth from "../hooks/useAuth";
import RecoverAccount from "../pages/RecoverPassword";

const AuthGuard = (props) => {
  const { children } = props;
  const auth = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const [requestedLocation, setRequestedLocation] = useState(null);

  if (!auth.isAuthenticated) {
    if (location.pathname !== requestedLocation) {
      setRequestedLocation(location.pathname);
    }

    if (requestedLocation === "/recoverAccount") {
      return <RecoverAccount />;
    } else {
      return <Navigate to="/login" />
    }
  }

  // This is done so that in case the route changes by any chance through other
  // means between the moment of request and the render we navigate to the initially
  // requested route.
  if (requestedLocation && location.pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  return <>{children}</>;
};

AuthGuard.propTypes = {
  children: PropTypes.node,
};

export default AuthGuard;
