import React, { Component, useState, useEffect } from "react";
import { Alert, Grid, TextField, Button } from "@mui/material";
import axios from "axios";

import { config } from "src/constants";
import { useNavigate } from "react-router-dom-v5-compat";

import { Link as RouterLink } from "react-router-dom-v5-compat";
import { Helmet } from "react-helmet-async";
import {
  Box,
  Card,
  CardContent,
  Container,
  Divider,
  Link,
  Typography,
} from "@mui/material";
import Logo from "src/components/Logo";

const RecoverPassword = () => {
  const [password, setPassowrd] = useState("");
  const [alert, setAlert] = useState({
    show: false,
    severity: "info",
    message: "",
  });

  const navigate = useNavigate();

  const resetPassword = () => {
    // Get the token from the query string

    let params = window.location.search
      .replace(/^\?/, "")
      .split("&")
      .reduce((obj, param) => {
        param = param.split("=");

        obj[param[0]] = param[1];

        return obj;
      }, {});

    let token = params["token?"];

    if (token === undefined)
      setAlert({
        show: true,
        severity: "error",
        message: "No login token can be found",
      });

    // Make the request to login with the token
    setAlert({
      show: true,
      severity: "info",
      message: "Logging in with token...",
    });

    axios
      .get(`${config.api}/auth/tokenLogin`, {
        params: {
          token,
        },
      })
      .then((res) => {
        let user = res.data;

        if (user.token) {
          axios.defaults.headers.common["Authorization"] =
            "Bearer " + user.token;

          setAlert({
            show: true,
            severity: "success",
            message: "Successfully logged in, changing password",
          });

          // Reset the password
          axios
            .post(`${config.api}/accounts/resetPassword`, {
              password: password,
            })
            .then(() => {
              setAlert({
                show: true,
                severity: "success",
                message: "Successfully changed password! Redirecting...",
              });

              navigate("/");
            });
        } else
          setAlert({
            show: true,
            severity: "error",
            message: "Something went wrong whilst trying to log in",
          });
      })
      .catch(() =>
        setAlert({
          show: true,
          severity: "error",
          message: "Failed to log in using token, it might've expired",
        })
      );
  };

  return (
    <>
      <Helmet>
        <title>Recover Password | Asset Aware Iot Gps Tracking</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: "background.default",
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh",
        }}
      >
        <Container maxWidth="sm" sx={{ py: "80px" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              mb: 8,
            }}
          >
            <RouterLink to="/">
              <Logo
                color="black"
                sx={{
                  height: 40,
                  width: 40,
                }}
              />
            </RouterLink>
          </Box>
          <Card>
            <CardContent
              sx={{
                display: "flex",
                flexDirection: "column",
                p: 4,
              }}
            >
              <Box
                sx={{
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "space-between",
                  mb: 3,
                }}
              >
                <div>
                  <Typography color="textPrimary" gutterBottom variant="h4">
                    Recover Password
                  </Typography>
                  {/* <Typography color="textSecondary" variant="body2">
                    
            </Typography>*/}
                </div>
              </Box>
              <Grid container direction="column" spacing={3}>
                <Grid item>
                  {/* Required, otherwise 'undefined' will be printed in the password box */}
                  <TextField hidden type="password" />

                  <TextField
                    label="New Password"
                    type="password"
                    autoComplete="current-password"
                    value={password}
                    onChange={(e) => setPassowrd(e.target.value)}
                    fullWidth
                  />
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    onClick={() => resetPassword()}
                    disabled={password.length === 0}
                  >
                    Reset Password
                  </Button>
                </Grid>
                <Grid item>
                  <Alert severity={alert.severity} hidden={!alert.show}>
                    {alert.message}
                  </Alert>
                </Grid>
              </Grid>
              <Divider sx={{ my: 3 }} />
            </CardContent>
          </Card>
        </Container>
      </Box>
    </>
  );
};

export default RecoverPassword;
