import { useEffect } from "react";
import { useRoutes, useNavigate } from "react-router-dom-v5-compat";
import { SnackbarProvider } from "notistack";
import { ThemeProvider } from "@mui/material";
import "./i18n";
import GlobalStyles from "./components/GlobalStyles";

import RTL from "./components/RTL";
import SettingsDrawer from "./components/SettingsDrawer";
import SplashScreen from "./components/SplashScreen";
import { gtmConfig } from "./config";
import useAuth from "./hooks/useAuth";
import useScrollReset from "./hooks/useScrollReset";
import useSettings from "./hooks/useSettings";
import gtm from "./lib/gtm";
import routes from "./routes";
import { createTheme } from "./theme/mongerTheme";
import axios from "axios";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import moment from "moment";
import "moment/locale/en-au";

var locale = window.navigator.userLanguage || window.navigator.language;

const HIDE_FAB_PATHS = [
  /^\/courier.*$/,
  /^\/sharing.*$/,
  /^.*\/assets$/,
  /^\/asset-dashboard.*$/,
  /^\/forms.*$/,
  /^\/form-submitted-thankyou.*$/,
];

const queryClient = new QueryClient();

const App = () => {
  const auth = useAuth();

  const navigate = useNavigate();
  const content = useRoutes(
    routes({ permissions: auth.user ? auth.user.permissions : null, navigate })
  );

  const { settings } = useSettings();

  axios.defaults.headers.common["Authorization"] =
    "Bearer " + localStorage.accessToken;

  useScrollReset();

  useEffect(() => {
    getLocale();
    gtm.initialize(gtmConfig);
  }, []);

  const getLocale = async () => {
    const { data } = await axios.get("https://ipapi.co/json/");

    moment.locale(`en-${data?.country_code.toLowerCase()}`);
  };
  

  const theme = createTheme({
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    roundedCorners: settings.roundedCorners,
    theme: settings.theme,
  });

  return (
    <ThemeProvider theme={theme}>
      <RTL direction={settings.direction}>
        <SnackbarProvider dense maxSnack={3}>
          <GlobalStyles />
          <QueryClientProvider client={queryClient}>
            {false && !HIDE_FAB_PATHS.reduce(
              (state, path) => state || path.test(location.pathname),
              false
            ) && <SettingsDrawer />}
            {auth.isInitialized ? content : <SplashScreen />}
          </QueryClientProvider>
        </SnackbarProvider>
      </RTL>
    </ThemeProvider>
  );
};

export default App;
