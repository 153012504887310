import react, { useEffect } from "react";
import { useState } from "react";
import { NavLink as RouterLink, useLocation } from "react-router-dom-v5-compat";
import PropTypes from "prop-types";
import {
  Box,
  Button,
  Collapse,
  IconButton,
  ListItem,
  Tooltip,
} from "@mui/material";
import ChevronDownIcon from "../icons/ChevronDown";
import ChevronRightIcon from "../icons/ChevronRight";

const NavItem = (props) => {
  const {
    active,
    children,
    depth,
    icon,
    info,
    open: openProp,
    path,
    title,
    setExpanded,
    expanded,
    ...other
  } = props;
  const [open, setOpen] = useState(openProp);
  const location = useLocation();

  useEffect(() => {
    if (!expanded) {
      setOpen(false);
    }
  }, [expanded]);

  const handleToggle = () => {
    setExpanded(true);
    setOpen((prevOpen) => !prevOpen);
  };

  let paddingLeft = 16;

  if (depth > 0) {
    paddingLeft = 32 + 8 * depth;
  }

  const isParentItem = Boolean(props.children);

  if (!expanded) {
    return (
      <ListItem
        disableGutters
        sx={{
          py: 0,
          justifyContent: "center",
        }}
      >
        <Tooltip
          title={title}
          placement="right"
          disableFocusListener={expanded}
          disableHoverListener={expanded}
          disableInteractive={expanded}
          disableTouchListener={expanded}
        >
          <IconButton
            component={path && RouterLink}
            sx={{
              color: "text.secondary",
              fontWeight: "fontWeightMedium",
              justifyContent: "flex-start",
              padding: "14.25px",
              pr: isParentItem ? 0 : "14.25px",
              textTransform: "none",
              "& svg": {
                opacity: 0.65,
              },
              ...(active && {
                color: "primary.main",
                fontWeight: "fontWeightBold",
                "& svg": {
                  color: "primary.main",
                  opacity: 1,
                },
              }),
            }}
            variant="text"
            onClick={isParentItem && handleToggle}
            to={!isParentItem ? path : location.pathname}
          >
            {icon}
            {isParentItem ? (
              !open ? (
                <ChevronRightIcon fontSize="small" />
              ) : (
                <ChevronDownIcon fontSize="small" />
              )
            ) : null}
          </IconButton>
        </Tooltip>
      </ListItem>
    );
  }

  return (
    <ListItem
      disableGutters
      sx={{
        display: "block",
        py: 0,
        whiteSpace: "initial",
      }}
      {...other}
    >
      <Button
        component={path && RouterLink}
        endIcon={
          isParentItem ? (
            !open ? (
              <ChevronRightIcon fontSize="small" />
            ) : (
              <ChevronDownIcon fontSize="small" />
            )
          ) : null
        }
        onClick={isParentItem && handleToggle}
        to={!isParentItem ? path : location.pathname}
        startIcon={icon}
        sx={{
          color: "text.secondary",
          fontWeight: "fontWeightMedium",
          justifyContent: "flex-start",
          pl: `${paddingLeft}px`,
          pr: "8px",
          py: "12px",
          textAlign: "left",
          textTransform: "none",
          width: "100%",
          "& svg": {
            opacity: 0.65,
          },
          ...(active && {
            color: "primary.main",
            fontWeight: "fontWeightBold",
            "& svg": {
              color: "primary.main",
              opacity: 1,
            },
          }),
        }}
        variant="text"
      >
        <Box
          sx={{
            flexGrow: 1,
            textOverflow: "elipsis",
            minWidth: isParentItem ? 175 : 200,
          }}
        >
          {title}
        </Box>
      </Button>
      {isParentItem && <Collapse in={open}>{children}</Collapse>}
    </ListItem>
  );
};

NavItem.propTypes = {
  active: PropTypes.bool,
  children: PropTypes.node,
  depth: PropTypes.number.isRequired,
  icon: PropTypes.node,
  info: PropTypes.node,
  open: PropTypes.bool,
  path: PropTypes.string,
  title: PropTypes.string.isRequired,
};

NavItem.defaultProps = {
  active: false,
  open: false,
};

export default NavItem;
