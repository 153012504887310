import { createSlice } from "@reduxjs/toolkit";
//import axios from "../lib/axios";
import axios from "axios";
import { config } from "../constants";

const initialState = {
  reports: [],
};

const slice = createSlice({
  name: "reports",
  initialState,
  reducers: {
    addReport(state, action) {
      state.reports = [...action.payload];
    },
  },
});

export const { reducer } = slice;

export const addReport =
  ({ params, data }) =>
  async (dispatch, getState) => {
    let { reports = [] } = getState().reports;

    let index = reports.findIndex((entry) => entry.params === params);
    if (index === -1) {
      let newReports = [...reports, { params, data: data }];

      dispatch(slice.actions.addReport(newReports));
    } else {
      if (data) {
        console.log("Add Data");
        // Edit the item with new Data

        let report = { ...reports[index], data };

        let newReports = [...reports];
        newReports.splice(index, 1, report);
        console.log(newReports);

        dispatch(slice.actions.addReport(newReports));
      }
    }

    console.log(index);
  };

export default slice;
