import "react-perfect-scrollbar/dist/css/styles.css";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "react-quill/dist/quill.snow.css";
import "nprogress/nprogress.css";
import "./__mocks__";
import { StrictMode } from "react";
import ReactDOM from "react-dom";
import { HelmetProvider } from "react-helmet-async";
import { BrowserRouter } from "react-router-dom-v5-compat";
import { Provider as ReduxProvider } from "react-redux";

import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import StyledEngineProvider from "@mui/material/StyledEngineProvider";
import App from "./App";
import { AuthProvider } from "./contexts/JWTContext";
import { SettingsProvider } from "./contexts/SettingsContext";
import { UserMapPrefProvider } from "./contexts/MapContext";
import { MapStatsProvider } from "./contexts/MapStatsContext";
import reportWebVitals from "./reportWebVitals";
import * as serviceWorker from "./serviceWorker";
import store from "./store";
import moment from "moment";

import { AdapterMoment } from "@mui/x-date-pickers-pro/AdapterMoment";

ReactDOM.render(
  <StrictMode>
    <HelmetProvider>
      <ReduxProvider store={store}>
        <StyledEngineProvider injectFirst>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <SettingsProvider>
              <UserMapPrefProvider>
                <MapStatsProvider>
                  <BrowserRouter>
                    <AuthProvider>
                      <App />
                    </AuthProvider>
                  </BrowserRouter>
                </MapStatsProvider>
              </UserMapPrefProvider>
            </SettingsProvider>
          </LocalizationProvider>
        </StyledEngineProvider>
      </ReduxProvider>
    </HelmetProvider>
  </StrictMode>,
  document.getElementById("root")
);

// If you want to enable client cache, register instead.
serviceWorker.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
