import { combineReducers } from "@reduxjs/toolkit";
import { reducer as calendarReducer } from "../slices/calendar";
import { reducer as chatReducer } from "../slices/chat";
import { reducer as kanbanReducer } from "../slices/kanban";
import { reducer as mailReducer } from "../slices/mail";
import { reducer as iotdevices } from "../slices/iotdevices";
import { reducer as user } from "../slices/user";
import { reducer as driversafety } from "../slices/driverSafety";
import { reducer as reports } from "../slices/reports";

const rootReducer = combineReducers({
  calendar: calendarReducer,
  chat: chatReducer,
  kanban: kanbanReducer,
  mail: mailReducer,
  user: user,
  iotdevices: iotdevices,
  driversafety: driversafety,
  reports: reports,
});

export default rootReducer;
