import { createContext, useEffect, useState } from "react";
import PropTypes from "prop-types";

const OVERLAYS = { NORMAL: "normal", SATELITE: "satelite" };

const initialUserMapPref = {
  overlay: OVERLAYS.NORMAL,
  customGeofences: false,
  jobsiteGeofences: false,
  vehicleLabels: false,
  railmap: false,
};

export const storeUserMapPref = (userMapPref) => {
  window.localStorage.setItem("user_map_pref", JSON.stringify(userMapPref));
};

export const restoreUserMapPref = () => {
  let userMapPref = null;

  try {
    const storedData = window.localStorage.getItem("user_map_pref");

    if (storedData) {
      userMapPref = { ...JSON.parse(storedData) };
    } else {
      userMapPref = {
        ...initialUserMapPref,
      };
    }
  } catch (err) {
    console.error(err);
  }

  return userMapPref;
};

const UserMapPrefContext = createContext({
  userMapPref: { ...initialUserMapPref },
  saveUserMapPref: () => {},
  addressSearch: { latitude: null, longitude: null },
  searchAddress: () => {},
});

export const UserMapPrefProvider = (props) => {
  const { children } = props;
  const [userMapPref, setUserMapPref] = useState(initialUserMapPref);
  const [addressSearch, setAddressSearch] = useState({
    latitude: null,
    longitude: null,
  });

  useEffect(() => {
    const restoredUserMapPref = restoreUserMapPref();

    if (restoredUserMapPref) {
      setUserMapPref({ ...restoredUserMapPref });
    }
  }, []);

  const saveUserMapPref = (updatedUserMapPref) => {
    const newUserMapPref = { ...userMapPref, ...updatedUserMapPref };
    setUserMapPref({ ...newUserMapPref });
    storeUserMapPref({ ...newUserMapPref });
  };

  const searchAddress = (address) => {
    setAddressSearch({ ...address });
  };

  return (
    <UserMapPrefContext.Provider
      value={{
        userMapPref,
        saveUserMapPref,
        addressSearch,
        searchAddress,
      }}
    >
      {children}
    </UserMapPrefContext.Provider>
  );
};

UserMapPrefProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const UserMapPrefConsumer = UserMapPrefContext.Consumer;

export default UserMapPrefContext;
