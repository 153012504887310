import { useEffect, useState } from "react";
import { Link as RouterLink, useLocation } from "react-router-dom-v5-compat";
import PropTypes from "prop-types";
import {
  Avatar,
  Box,
  Divider,
  Drawer,
  Hidden,
  Link,
  IconButton,
  Typography,
  Button,
} from "@mui/material";
import {
  Timer,
  SupervisorAccount,
  ListAlt,
  List,
  Notifications,
  LocalOffer,
  CameraAlt,
  Share,
  AccountTree,
  DataUsage,
  CarRental,
  SettingsInputComponent,
  MenuBook,
  DeviceHub,
  LocalShipping,
  Grading,
  AirplaneTicket,
  CardMembership,
} from "@mui/icons-material";
import useAuth from "../../hooks/useAuth";

import ChartSquareBarIcon from "../../icons/ChartSquareBar";

import UserIcon from "../../icons/User";
import Business from "@mui/icons-material/Business";

import People from "@mui/icons-material/People";
import ChevronLeftIcon from "src/icons/ChevronLeft";
import ChevronRightIcon from "src/icons/ChevronRight";
import BorderClearIcon from "@mui/icons-material/BorderClear";
import colors from "src/colors";
import AccountTreeIcon from "@mui/icons-material/AccountTree";

import NavSection from "../NavSection";
import Scrollbar from "../Scrollbar";
import { AcUnit, DriveEta, Warning } from "@mui/icons-material";
import { styled } from "@mui/material/styles";

import { LicenseInfo } from "@mui/x-data-grid-premium";

LicenseInfo.setLicenseKey(
  "e3068cf7f8f954ff79f27885ad9b6164Tz04OTUyNCxFPTE3NDYxNTQwNjMwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y"
);

const DRAWER_WIDTH = 280;
const AUTO_MINIMISE_URL = [/\/dashboard\/assets\/device[^ ]*/i];

const openedMixin = (theme) => ({
  width: DRAWER_WIDTH,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(10)} + 1px)`,
  minWidth: "80px",
});

const ExpandableDrawer = styled(Drawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: DRAWER_WIDTH,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const sections = [
  {
    title: "General",
    items: [
      // {
      //   title: "Dashboard",
      //   path: "/dashboard/fleet",
      //   icon: <ChartSquareBarIcon fontSize="small" />,
      // },

      {
        title: "Fleet & Asset List",
        path: "/dashboard/assets",
        icon: <DriveEta fontSize="small" />,
      },

      {
        title: "Warboard",
        path: "/dashboard/warboard",
        icon: <Grading fontSize="small" />,
        feature: "warboard",
      },
      {
        title: "Fleet Management",
        icon: <CarRental fontSize="small" />,
        children: [
          {
            title: "Notifications",
            path: "/dashboard/notifications",
            icon: <Notifications fontSize="small" />,
          },
          {
            title: "Vehicle Maintenance Dashboard",
            path: "/dashboard/vehicle-maintenance",
            icon: <List fontSize="small" />,
            feature: "vehicle_task_management",
          },
          {
            title: "Trips",
            path: "/dashboard/trips",
            icon: <Share fontSize="small" />,
          },
          {
            title: "Overview Map",
            path: "/dashboard/overview-map",
            icon: <AccountTree fontSize="small" />,
          },
          {
            title: "Beacon Overview Map",
            path: "/dashboard/beacon-overview-map",
            icon: <AccountTree fontSize="small" />,
          },
          {
            title: "Driver Safety",
            path: "/dashboard/driversafety",
            icon: <Warning fontSize="small" />,
            feature: "driver_safety",
          },
          {
            title: "Inputs",
            path: "/dashboard/inputs",
            icon: <SettingsInputComponent fontSize="small" />,
            feature: "inputs",
          },
          {
            title: "Timeline",
            path: "/dashboard/timeline",
            icon: <SettingsInputComponent fontSize="small" />,
          },
          {
            title: "Logbook",
            path: "/dashboard/logbook",
            icon: <MenuBook fontSize="small" />,
            feature: "logbook",
          },
        ],
      },

      {
        title: "Reporting",
        path: "/dashboard/reports",
        icon: <ListAlt fontSize="small" />,
        feature: "reporting",
      },

      {
        title: "Cold Chain",
        path: "/dashboard/environment",
        icon: <AcUnit fontSize="small" />,
        feature: "cold_chain",
      },

      {
        title: "Driver Management",
        path: "/dashboard/drivermanagement",
        icon: <People fontSize="small" />,
        feature: "driver_management",
      },

      {
        title: "Dashcam Events",
        path: "/dashboard/dashcam-events",
        icon: <CameraAlt fontSize="small" />,
        feature: "dashcam",
      },

      {
        title: "Automations",
        path: "/dashboard/automations",
        icon: <AccountTreeIcon fontSize="small" />,
        feature: "automation",
      },

      {
        title: "My Account",
        path: "/dashboard/account",
        icon: <UserIcon fontSize="small" />,
      },
    ],
  },
  /*
  {
    title: "Moncrete",
    feature: "concrete_curing",
    items: [
      {
        title: "Moncrete",
        path: "/dashboard/concrete/dashboard",
        icon: <Timer fontSize="small" />,
        children: [
          {
            title: "Dashboard",
            path: "/dashboard/concrete/dashboard",
          },

          {
            title: "Admin",
            path: "/dashboard/concrete",
            icon: <Warning fontSize="small" />,
            children: [
              {
                title: "Mix Design",
                path: "/dashboard/concrete/mix-designs",
              },
              {
                title: "Mass Concrete Limits",
                path: "/dashboard/concrete/mass-concrete-limits",
              },
              {
                title: "Evaporation Limits",
                path: "/dashboard/concrete/evaporation-limits",
              },
              {
                title: "Maturity Inputs",
                path: "/dashboard/concrete/maturity-inputs",
              },
              {
                title: "Pours",
                path: "/dashboard/concrete/Pours",
              },
              {
                title: "Sensors",
                path: "/dashboard/concrete/sensors",
              },
            ],
          },
        ],
      },
    ],
  },
  */

  {
    title: "Management",
    items: [
      {
        title: "Maintenance Management",

        icon: <ListAlt fontSize="small" />,
        feature: "vehicle_task_management",
        children: [
          {
            title: "Maintenance Task Groups",
            path: "/dashboard/task-groups",
            icon: <List fontSize="small" />,
            //feature: "vehicle_task_management",
          },
          {
            title: "Tasks",
            path: "/dashboard/tasks",
            icon: <List fontSize="small" />,
            //feature: "vehicle_task_management",
          },
          {
            title: "Task Templates",
            path: "/dashboard/task-templates",
            icon: <List fontSize="small" />,
            //feature: "vehicle_task_management",
          },
        ],
      },
      /*
      {
        title: "Timesheet",
        path: "/dashboard/timesheet",
        icon: <Timer fontSize="small" />,
        feature: "timesheet",
        children: [
          {
            title: "View Timesheet",
            path: "/dashboard/timesheet/view",
            permissions: "review_timesheet",
          },
          {
            title: "Payroll",
            path: "/dashboard/timesheet/payroll",
            permissions: "admin_timesheet",
          },
          {
            title: "Review Timesheet",
            path: "/dashboard/timesheet/review",
            permissions: "access_timesheet",
          },

          {
            title: "Reports",
            path: "/dashboard/timesheet/reports",
            icon: <Warning fontSize="small" />,
            permission: "access_timesheet",
            children: [
              {
                title: "Job Report",
                path: "/dashboard/timesheet/reports/job",
                permission: "access_timesheet",
              },
              {
                title: "Structure Timesheet",
                path: "/dashboard/timesheet/reports/structure",
                permission: "access_timesheet",
              },
              {
                title: "Overtime Timesheet",
                path: "/dashboard/timesheet/reports/overtime",
                permission: "access_timesheet",
              },
              {
                title: "Missing Shifts",
                path: "/dashboard/timesheet/reports/missingShifts",
                permission: "access_timesheet",
              },
              {
                title: "No Login",
                path: "/dashboard/timesheet/reports/noLogin",
                permission: "access_timesheet",
              },
            ],
          },
        ],
      },
      */
      /*
      {
        title: "Tickets",
        path: "/dashboard/tickets",
        icon: <CardMembership fontSize="small" />,
        feature: "timesheet",
        children: [
          {
            title: "Ticket Admin",
            path: "/dashboard/tickets/admin",
            permissions: "tickets_edit",
          },
          {
            title: "Matrix",
            path: "/dashboard/tickets/matrix",
            permissions: "tickets_view",
          },
          {
            title: "Approvals",
            path: "/dashboard/tickets/approvals",
            permissions: "tickets_add",
          },
        ],
      },
      */

      /*
      {
        title: "Covid19",
        path: "/dashboard/timesheet/reports/covid19",
        icon: <HealthAndSafety fontSize="small" />,
      },
      */
      /*
      {
        title: "Suppliers",
        path: "/dashboard/suppliers",
        icon: <EmojiTransportation fontSize="small" />,
        children: [
          {
            title: "Supplier List",
            path: "/dashboard/suppliers",
          },
        ],
      },
      */
      {
        title: "Geofences",
        path: "/dashboard/geofences",
        icon: <BorderClearIcon fontSize="small" />,
      },
      {
        title: "Beacons",
        path: "/dashboard/beacons",
        icon: <SupervisorAccount fontSize="small" />,
        children: [
          {
            title: "Beacons",
            path: "/dashboard/beacons",
            icon: <DeviceHub fontSize="small" />,
            feature: "ble_beacons",
          },
          {
            title: "Beacon Config",
            path: "/dashboard/beacon-config",
            icon: <DeviceHub fontSize="small" />,
            feature: "ble_beacons",
          },
        ],
      },

      /*
      {
        title: "Courier",
        path: "/courier",
        icon: <LocalShipping fontSize="small" />,
      },*/

      /*
      {
        title: "Subscriptions",
        path: "/dashboard/subscriptions",
        icon: <LocalOffer fontSize="small" />,
      },
    */

      {
        title: "Admin",
        path: "/dashboard/suppliers",
        icon: <SupervisorAccount fontSize="small" />,
        children: [
          {
            title: "Company",
            path: "/dashboard/company_settings",
            icon: <Business fontSize="small" />,
            permission: "edit_permissions",
          },
          {
            title: "Account / Device Linking",
            path: "/dashboard/admin/accdevlink",
          },

          {
            title: "People",
            path: "/dashboard/admin/people",
            permission: "people_view",
          },
          {
            title: "Jobsites",
            path: "/dashboard/admin/jobsites",
            permission: "jobsites_view",
          },

          {
            title: "Groups",
            path: "/dashboard/admin/groups",
            permission: "groups_view",
          },
          /*
          {
            title: "Jobcodes",
            path: "/dashboard/admin/jobcodes",
            permission: "codes_view",
          },*/
          /*
          {
            title: "Allowances",
            path: "/dashboard/admin/allowances",
            permission: "admin_timesheet",
            feature: "timesheet",
          },

          {
            title: "Payroll Groups",
            path: "/dashboard/admin/payrollgroups",
            permission: "admin_timesheet",
            feature: "timesheet",
          },
          */

          {
            title: "Insight Tags",
            path: "/dashboard/insight-tags",
            //permission: "admin_timesheet",
            feature: "insight_tags",
          },
          {
            title: "Driver Tags",
            path: "/dashboard/driver-tags",
            //permission: "admin_timesheet",
            feature: "driver_management",
          },
        ],
      },

      {
        title: "Forms",
        icon: <ListAlt fontSize="small" />,
        feature: "forms",
        children: [
          {
            title: "Admin",
            path: "/dashboard/forms/admin",
            permission: "forms_add",
          },
          {
            title: "Complete",
            path: "/dashboard/forms/selection",
            permission: "complete_forms",
          },
          {
            title: "View Completed",
            path: "/dashboard/forms/completed",
            permission: "forms_view",
          },
          {
            title: "Review Forms",
            path: "/dashboard/forms/review",
            permission: "forms_edit",
          },
        ],
      },
    ],
  },
];

const DashboardSidebar = (props) => {
  const { onMobileClose, openMobile } = props;
  const [expanded, setExpanded] = useState(true);
  const location = useLocation();
  const { user } = useAuth();

  const filteredSections = user.tsadmin
    ? sections.map((section) => ({
        ...section,
        items: section.items.filter(
          (item) => item.title === "Fleet & Asset List"
        ),
      }))
    : sections;

  const { features_enabled = {} } = user;

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }

    for (let i = 0; i < AUTO_MINIMISE_URL.length; i++) {
      let regex = new RegExp(AUTO_MINIMISE_URL[i]);
      if (regex.test(location.pathname)) {
        setExpanded(false);
        break;
      }
    }
  }, [location.pathname]);

  const generateContent = (mobileMode) => (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <Hidden lgUp>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            p: 2,
          }}
        >
          <RouterLink to="/">{/*<Logo color="black" />*/}</RouterLink>
        </Box>
      </Hidden>
      <Box sx={{ p: 2, position: "relative" }}>
        {expanded || mobileMode ? (
          <Box
            sx={{
              mt: { xs: 4, lg: 0 },
              alignItems: "center",
              backgroundColor: "background.default",
              borderRadius: 1,
              display: "flex",
              overflow: "hidden",
              p: 2,
            }}
          >
            <RouterLink to="/dashboard/account">
              <Avatar
                src={`${user.photo}`}
                sx={{
                  cursor: "pointer",
                  height: 48,
                  width: 48,
                }}
              />
            </RouterLink>
            <Box sx={{ ml: 2 }}>
              <Typography color="textPrimary" variant="subtitle2">
                {user.name}
              </Typography>
              <Typography color="textSecondary" variant="body2">
                <Link color="primary" component={RouterLink} to="/dashboard">
                  {user.first_name} {user.last_name}
                </Link>
              </Typography>
            </Box>
          </Box>
        ) : (
          <Box
            sx={{
              py: 2,
            }}
          >
            <RouterLink to="/dashboard/account">
              <Avatar
                src={`data:image/png;base64,${user.avatar}`}
                sx={{
                  cursor: "pointer",
                  height: 48,
                  width: 48,
                }}
              />
            </RouterLink>
          </Box>
        )}
        <IconButton
          onClick={() => {
            setExpanded((prevState) => !prevState);
          }}
          sx={{
            display: { xs: "none", sm: "none", md: "none", lg: "flex" },
            position: "absolute",
            right: 0,
            bottom: 0,
            transform: "translate(50%, 50%)",
            zIndex: 1000,
            backgroundColor: colors.white,
            border: "1px solid rgba(0, 0, 0, 0.12)",
          }}
          color="primary"
        >
          {!expanded ? (
            <ChevronRightIcon fontSize="small" />
          ) : (
            <ChevronLeftIcon fontSize="small" />
          )}
        </IconButton>
      </Box>
      <Divider />
      <Scrollbar options={{ suppressScrollX: true }}>
        <Box sx={{ p: 2 }}>
          {filteredSections.map((section) => {
            return (
              <>
                {(user?.permissions[section.permission] ||
                  !section.permission) &&
                  (features_enabled[section.feature] || !section.feature) && (
                    <NavSection
                      user={user}
                      setExpanded={setExpanded}
                      expanded={expanded || mobileMode}
                      key={section.title}
                      pathname={location.pathname}
                      sx={{
                        "& + &": {
                          mt: 3,
                        },
                      }}
                      {...section}
                    />
                  )}
              </>
            );
          })}
        </Box>
        <Divider />
      </Scrollbar>
      {/*
      <Box sx={{ p: 2, display: expanded ? "block" : "none" }}>
        <Typography color="textPrimary" variant="subtitle2">
          Require Support or Found a Bug?
        </Typography>
        <Typography color="textSecondary" variant="body2">
          Log an issue with our support team.
        </Typography>
        <Button
          color="primary"
          fullWidth
          sx={{ mt: 2 }}
          component="a"
          href="https://support.monger.tech/portal/en/newticket"
          variant="contained"
          target="_blank"
          rel="noopener noreferrer"
        >
          Report Issue
        </Button>
        <Box />

      </Box>
      */}
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          onClose={onMobileClose}
          open={openMobile}
          PaperProps={{
            sx: {
              backgroundColor: "background.paper",
              width: DRAWER_WIDTH,
            },
          }}
          variant="temporary"
        >
          {generateContent(true)}
        </Drawer>
      </Hidden>
      <Hidden lgDown>
        <ExpandableDrawer
          style={{ overflow: "visible", zIndex: 1222 }}
          anchor="left"
          open={expanded}
          onClose={() => {
            setExpanded(true);
          }}
          PaperProps={{
            sx: {
              backgroundColor: "background.paper",
              height: "calc(100% - 64px) !important",
              top: "64px !Important",
              overflow: "visible !important",
              width: DRAWER_WIDTH,
            },
          }}
          variant="permanent"
        >
          {generateContent()}
        </ExpandableDrawer>
      </Hidden>
    </>
  );
};

DashboardSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default DashboardSidebar;
